var app = {};

app.init = function () {
    $(document).foundation();
    var html = document.getElementsByTagName('html');
    html[0].className += ' ' + 'hny-ui';

    console.log("%cWebsite Design by", "font: 200 16px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#CCC");
    console.log("%cHoneycomb Creative", "font: 200 28px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#ffcc05");
    console.log("%chttps://www.honeycombcreative.com", "font: 200 12px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#CCC");

    $('[data-tabs]').on('change.zf.tabs', function (e) {
        const el = $(e.currentTarget);
        const id = el.attr('id');
        const select = $('select[data-select-tabs="' + id + '"');

        $('[data-slick].slick-initialized').slick('setPosition');

        if(select) {
            const activePanelLink = el.find('.tabs-title.is-active > a');

            if(activePanelLink) {
                select.val(activePanelLink.attr('href'));
            }
        }
    });

    $('select[data-select-tabs]').on('change', function(e) {
        const el = $(e.currentTarget);
        const tabs = $('#' + el.data('select-tabs'));
        const panelId = el.val();

        if(tabs) {
            tabs.foundation('selectTab', $(panelId));
        }
    });
}(),
    app.scrollTracking = function ($) {
        $.scrollDepth({
            elements: ['#HomepagePastVideo'],
            percentage: false,
            pixelDepth: false,
            nonInteraction: false
        });
    }(jQuery),
    app.sliders = function ($) {
        function slidersInit() {
            var hero = $('.hero-slider'),
                slider = $('[data-hny-slider]');

            hero.slick({
                dots: false,
                infinite: true,
                arrows: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: false,
                fade: true,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: false,
            });

            slider.slick({
                dots: true,
                infinite: true,
                arrows: true,
                prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: false,
                fade: true,
                autoplay: true,
                autoplaySpeed: 1000,
                pauseOnHover: false,
            });
        }

        $(document).on('ready', slidersInit);
    }(jQuery),
    app.videoCta = function ($) {
        function videoCtaInit() {
            var el = $('.js-video-cta-toggle'),
                body = $('body');

            el.on('click', function(e) {
                ctaClick(e);
            });

            if(Cookies.get('video_cta') !== 'false') {
                body.addClass('show-video-cta');
            }

            function ctaClick(e) {
                e.preventDefault();
                var state = '';

                if(body.hasClass('show-video-cta')) {
                    state = true;
                }
                body.toggleClass('show-video-cta');

                Cookies.set('video_cta', !state, { expires: 7 });
            }
        }

        $(document).on('ready', videoCtaInit);
    }(jQuery),
    app.stickyNav = function ($) {
        function stickyNavInit() {
            var subNav = $('.sub-nav');

            subNav.addClass('-is-sticky');

            function isPositionSticky(selector) {
                return ($.inArray($(selector).css('position'), ['-webkit-sticky', '-moz-sticky', '-ms-sticky', '-o-sticky', 'sticky']) != -1);
            }

            if (!isPositionSticky('.sub-nav'))
                $('.sub-nav').scrollToFixed();

            $('.sub-nav__select').change(function (e) {
                var page = $('.sub-nav select option:selected').val();
                window.location = page;
            });
        }

        $(document).on('ready', stickyNavInit);
    }(jQuery),
    app.openModal = function ($) {
        function openModalInit() {
            function clickModal(e) {
                e.preventDefault();
            }

            $('[data-open]').on('click', clickModal);
        }

        $(document).on('ready', openModalInit());
    }(jQuery),
    app.megaMenu = function ($) {
        function megaMenuInit() {
            function megaMenuToggle(e) {
                e.preventDefault();

                var selected = $(this),
                    body = $('body');

                $('.header__submenu').removeClass('-is-visible');
                $('.header .menu-item-has-children > a').removeClass('-selected');

                if (selected.next('div').hasClass('-is-hidden')) {
                    selected.addClass('-selected').next('div').removeClass('-is-hidden').addClass('-is-visible').end().parent('.menu-item-has-children');
                    selected.parent('.menu-item-has-children').siblings('.menu-item-has-children').children('div').addClass('-is-hidden').end().children('a').removeClass('-selected');
                    body.addClass('-has-mega-menu');
                } else {
                    selected.removeClass('-selected').next('div').removeClass('-is-visible').addClass('-is-hidden').end();
                    body.removeClass('-has-mega-menu');
                }
            }

            $('.header__nav .menu-item-has-children').children('a').on('click', megaMenuToggle);

            $(document).on('click', function (e) {
                var menu = $('.header .menu-item-has-children');

                if (!$(e.target).is(menu) && (menu.has(e.target).length) === 0) {
                    $('a.-selected').trigger('click');
                }
            });
        }

        $(document).on('ready', megaMenuInit);
    }(jQuery),
    app.mobileNav = function ($) {
        function mobileNavInit() {
            function mobileNavToggle(e) {
                e.preventDefault();

                var body = $('body'),
                    nav = $('.mobile-nav');

                $('.header__nav li.-mega-menu-open > a').trigger('click');
                body.toggleClass('-has-mobile-nav');
                if (body.hasClass('-has-mobile-nav')) {
                    //body.on('touchmove', false);
                } else {
                    //body.off('touchmove', false);
                }
            }

            $('.header__navburger').on('click', mobileNavToggle);
        }

        $(document).on('ready', mobileNavInit);
    }(jQuery),
    app.search = function ($) {
        function searchInit() {
            $('.header__search .search__input').on('focus', function () {
                var search = $(this).closest('.search'),
                    suggestions = search.find('.search__suggestions');

                search.addClass('-is-open');
                suggestions.css({'max-height': suggestions.find('ul').outerHeight()});
            }).on('keyup', function (e) {
                if (e.which === 27) $(this).blur();
            });

            $(document).on('click touchstart', function (e) {
                var search = $('.search.-is-open');

                if (!$(e.target).is(search) && (search.has(e.target).length) === 0) {
                    closeSearch();
                }
            }).on('keyup', function (e) {
                if (e.which === 27) closeSearch();
            });

            $('.header__search .search__submit').on('click', function (e) {
                var search = $(this).closest('.search');

                if (!search.hasClass('-is-open')) {
                    e.preventDefault();
                    search.find('.search__input').focus();
                }
            });

            var closeSearch = function () {
                var search = $('.search.-is-open');

                search.removeClass('-is-open').find('.search__input').blur();
            };
        }

        $(document).on('ready', searchInit);
    }(jQuery),
    app.scrollTarget = function ($) {
        function scrollTargetInit() {
            function scrollDown(e) {
                e.preventDefault();

                var _this = $(this),
                    body = $('body'),
                    adminbar = $('#wpadminbar'),
                    subnav = $('.sub-nav'),
                    target = '.' + _this.data('hny-scroll-target'),
                    offset = (subnav && subnav.length) ? $(target).offset().top - subnav.outerHeight() : $(target).offset().top,
                    adminbarOffset = (body.hasClass('admin-bar') && (adminbar && adminbar.length)) ? '32' : '0';

                $('body, html').animate({
                        scrollTop: offset - adminbarOffset
                    }, 700
                );

            }

            $('[data-hny-scroll-target]').on('click', scrollDown);
        }

        $(document).on('ready', scrollTargetInit);
    }(jQuery),
    app.stickyFooter = function ($) {
        function stickyFooterInit() {
            var footer = $('.footer'),
                pos = footer.position(),
                height = $(window).height();

            height = height - pos.top;
            height = height - footer.height() - 1;

            if (height > 0) {
                footer.css({
                    'margin-top': height + 'px'
                });
            }
        }

        //$(window).bind(' load resize orientationChange ', stickyFooterInit);
    }(jQuery),
    app.flexVideo = function ($) {
        function flexVideoInit() {
            $('iframe[src*="youtube.com"]').wrap("<div class='flex-video widescreen'/>");
            $('iframe[src*="vimeo.com"]').wrap("<div class='flex-video widescreen vimeo'/>");
        }

        $(document).on('ready', flexVideoInit);
    }(jQuery),
    app.externalLinks = function ($) {
        function externalLinksInit() {
            $('.main a:not(".-hide-external-link"):not(".gform_anchor")').each(function () {
                var a = new RegExp('/' + window.location.host + '/');
                if (!a.test(this.href)) {
                    $(this).addClass('-is-external-link').attr('target', '_blank');
                }
            });
        }

        $(document).on('ready', externalLinksInit);
    }(jQuery),
    app.backToTop = function ($) {
        function backToTopInit() {
            function backToTopHandler() {
                var backtotop = $('.js-back-to-top');

                ( $(window).scrollTop() > 300 ) ? backtotop.addClass('-is-visible') : backtotop.removeClass('-is-visible');
            }

            function scrollToTop(e) {
                e.preventDefault();
                $('body, html').animate({
                        scrollTop: 0,
                    }, 700
                );
            }

            $('.js-back-to-top').on('click', scrollToTop);
            $(window).scroll(backToTopHandler);
        }

        $(document).on('ready', backToTopInit);
    }(jQuery);