if (typeof(angular) !== 'undefined') {
    (function (angular) {
        var html = document.getElementsByTagName('html');
        html[0].className += ' ' + 'angular';

        var directives = angular.module('directives', []);
        var services = angular.module('services', []);
        var controllers = angular.module('controllers', ['services']);
        var filters = angular.module('filters', []);

        directives.directive('loading', ['$http', function ($http) {
            return {
                restrict: 'A',
                link: function ($scope, $element, $attr) {
                    $scope.isLoading = function () {
                        return $http.pendingRequests.length > 0;
                    };
                    $scope.$watch($scope.isLoading, function (value) {
                        if (value) {
                            $element.addClass('-loading');
                        } else {
                            $element.removeClass('-loading');
                        }
                    });
                }
            };
        }]);

        directives.directive('accordionInit', function () {
            return function (scope, element, attrs) {
                if (scope.$last) {
                    var $accordion = new Foundation.Accordion(angular.element('.accordion'), {
                        allowAllClosed: true,
                    });
                }
            }
        });

        directives.directive('inputBlur', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var textFields = element.find('input');

                    element.bind('submit', function () {
                        console.log('form was submitted');
                        textFields[0].blur();
                    });
                }
            };
        });

        services.service('wpDataService', ['$http', function ($http) {
            var urlBase = window.location.protocol + '//' + window.location.host;

            this.getData = function (apiBase) {
                var apiURL = urlBase + apiBase;

                return $http.get(apiURL);
            };
        }]);

        filters.filter('startsWith', function () {
            return function (input, alpha, property) {

                var _out = [];

                if (angular.isUndefined(alpha)) {
                    _out = input;
                }

                angular.forEach(input, function (item) {
                    var filteritem = item;
                    if (angular.isDefined(property) && item[property]) {
                        filteritem = item[property];
                    }
                    if (filteritem.charAt(0).toLowerCase() == alpha) {
                        _out.push(item);
                    }
                });
                return _out;
            };
        });

        controllers.controller('glossary', ['$scope', '$http', '$sce', 'wpDataService', function ($scope, $http, $sce, wpDataService) {
            function loadGlossary() {
                wpDataService.getData(apiBase).success(function (data) {
                    var terms = [];
                    for (var i = data.custom_fields.terms.length - 1; i >= 0; i--) {
                        var glossary = data.custom_fields;
                        terms[i] = {
                            term: glossary.terms[i].term,
                            definition: glossary.terms[i].description,
                            photo: glossary.terms[i].photo,
                        };
                    }
                    $scope.hny.alphaList = createAlphaList(angular.fromJson(terms), 'term');
                    $scope.hny.selectedTerms = angular.fromJson(terms);
                });
            }

            function createAlphaList(terms, property) {
                var list = [];
                angular.forEach(terms, function (term) {
                    term = term[property] || term;
                    var _alpha = term.charAt(0);
                    if (list.indexOf(_alpha) === -1) {
                        list.push(_alpha);
                    }
                });

                return list.sort();
            }

            function selectTerms(alpha) {
                $scope.hny.filterAlpha = (alpha) ? alpha.toLowerCase() : undefined;
            };

            $scope.hny = {};
            var apiBase = '/wp-json/wp/v2/pages/175';
            $scope.hny.selectTerms = selectTerms;
            loadGlossary();
        }]);

        controllers.controller('engineeringInfo', ['$scope', '$http', '$sce', 'wpDataService', '$modal', function ($scope, $http, $sce, wpDataService, $modal) {
            function open($event, modalData) {
                $event.preventDefault();

                var modalInstance = $modal.open({
                    templateUrl: 'modal.html',
                    controller: ['$scope', '$rootScope', '$modalInstance',
                        function ($scope, $rootScope, $modalInstance) {
                            $scope.data = angular.extend($scope, modalData);

                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }],
                    resolve: {}
                });
                return modalInstance;
            }

            function loadCats() {
                wpDataService.getData(apiBase).success(function (data) {
                    var cats = [];
                    for (var i = data.length - 1; i >= 0; i--) {
                        cats[i] = {
                            name: data[i].name,
                            description: data[i].description,
                            id: data[i].id,
                        };
                    }
                    $scope.hny.cats = cats;
                });
            };

            function loadArticles(id, $event) {
                var apiBase = '/wp-json/wp/v2/engineering_info?engineering-category=' + id + '&per_page=100';
                var expanded = $event.currentTarget.attributes['aria-expanded'].nodeValue;

                $scope.hny.articles = {};

                if(expanded.length && expanded === 'false') {
                    wpDataService.getData(apiBase).success(function (data) {
                        for (var i = data.length - 1; i >= 0; i--) {
                            data[i].title = $sce.trustAsHtml(data[i].title.rendered);
                            data[i].content = $sce.trustAsHtml(data[i].content.rendered);
                            data[i].author = data[i].author_meta;
                            data[i].pdf = data[i].custom_fields.pdf_attachment;
                            data[i].pdf_title = data[i].custom_fields.pdf_title;
                            data[i].youtube = data[i].custom_fields.youtube_video;
                            data[i].link = data[i].custom_fields.external_link;
                            data[i].link_title = data[i].custom_fields.external_link_title;
                            data[i].show_sub_items = data[i].custom_fields.show_sub_items;
                            data[i].sub_items = {};

                            if(data[i].show_sub_items === true) {
                                data[i].sub_items = data[i].custom_fields.sub_items;
                            }
                        }
                        $scope.hny.articles = data;
                    });
                }
            }

            $scope.hny = {};
            var apiBase = '/wp-json/wp/v2/engineering-category';
            $scope.hny.oneAtATime = true;
            $scope.hny.loadArticles = loadArticles;
            $scope.hny.open = open;
            loadCats();
        }]);

        controllers.controller('materials', ['$scope', '$http', '$sce', 'wpDataService', function ($scope, $http, $sce, wpDataService) {
            function loadCats(apiBase, $event) {
                $scope.hny.materials = {};

                wpDataService.getData(apiBase).success(function (data) {
                    for (var i = data['items'].length - 1; i >= 0; i--) {
                        data['items'][i].title = $sce.trustAsHtml(data['items'][i].title);
                    }
                    $scope.hny.materials = data['items'];
                });
            }

            $scope.hny = {};
            var apiBase = '/wp-json/ph-materials/v1/materials';
            loadCats(apiBase);
        }]);

        controllers.controller('faq', ['$scope', '$http', '$sce', 'wpDataService', function ($scope, $http, $sce, wpDataService) {
            function loadFAQs() {
                wpDataService.getData(apiBase).success(function (data) {
                    var faqs = [];
                    for (var i = data.custom_fields.frequently_asked_questions.length - 1; i >= 0; i--) {
                        faqs[i] = {
                            question: data.custom_fields.frequently_asked_questions[i].question,
                            answer: $sce.trustAsHtml(data.custom_fields.frequently_asked_questions[i].answer),
                            search: data.custom_fields.frequently_asked_questions[i].question + ' ' + $sce.trustAsHtml(data.custom_fields.frequently_asked_questions[i].answer)
                        };
                    }
                    $scope.hny.faqs = faqs;
                });
            };

            function isFiltered() {
                return $scope.hny.search || !1;
            }

            function filteredCount() {
                if ($scope.hny.filtered && $scope.hny.filtered.length) return $scope.hny.filtered.length;
            }

            function resetFilter($event) {
                $event.preventDefault();
                $scope.hny.search = undefined;
                $scope.hny.filtered = undefined;
            }

            $scope.hny = {};
            var apiBase = '/wp-json/wp/v2/pages/89';
            $scope.hny.isFiltered = isFiltered;
            $scope.hny.filteredCount = filteredCount;
            $scope.hny.resetFilter = resetFilter;
            loadFAQs();
        }]);

        controllers.controller('jobs', ['$scope', '$http', '$sce', 'wpDataService', function ($scope, $http, $sce, wpDataService) {
            function loadJobs() {
                wpDataService.getData(apiBase).success(function (data) {
                    for (var i = data.length - 1; i >= 0; i--) {
                        data[i].title = $sce.trustAsHtml(data[i].title.rendered);
                        data[i].content = $sce.trustAsHtml(data[i].content.rendered);
                    }
                    $scope.hny.jobs = data;
                });
            };

            function isFiltered() {
                return $scope.hny.search || !1;
            }

            function filteredCount() {
                if ($scope.hny.filtered && $scope.hny.filtered.length) return $scope.hny.filtered.length;
            }

            function resetFilter($event) {
                $event.preventDefault();
                $scope.hny.search = undefined;
                $scope.hny.filtered = undefined;
            }

            $scope.hny = {};
            var apiBase = '/wp-json/wp/v2/careers?per_page=100';
            $scope.hny.isFiltered = isFiltered;
            $scope.hny.filteredCount = filteredCount;
            $scope.hny.resetFilter = resetFilter;
            loadJobs();
        }]);

        controllers.controller('products', ['$scope', '$http', '$sce', 'wpDataService', '$modal', function ($scope, $http, $sce, wpDataService, $modal) {
            $scope.hny = {};

            function open(modalData) {
                var modalInstance = $modal.open({
                    templateUrl: 'modal.html',
                    controller: ['$scope', '$rootScope', '$modalInstance',
                        function ($scope, $rootScope, $modalInstance) {
                            $scope.data = angular.extend($scope, modalData);

                            $scope.cancel = function () {
                                $modalInstance.dismiss('cancel');
                            };
                        }],
                    resolve: {}
                });
                return modalInstance;
            }

            function loadProducts() {
                wpDataService.getData(apiBase).success(function (data) {
                    for (var i = data['items'].length - 1; i >= 0; i--) {
                        var types = [];
                        angular.forEach(data['items'][i].classification, function (item) {
                            types[item.slug] = item;
                        });

                        data['items'][i].type = types;
                    }
                    $scope.hny.products = data['items'];
                });
            }

            function isFiltered() {
                return $scope.hny.filter || !1;
            }

            function filteredCount() {
                if ($scope.hny.filtered && $scope.hny.filtered.length) return $scope.hny.filtered.length;
            }

            function resetFilter($event) {
                $event.preventDefault();
                $scope.hny.filter = undefined;
                $scope.hny.filtered = undefined;
            }

            $scope.hny.doFilter = function (param, track) {
                if (!(param && param.length)) return;

                if (track === false) $scope.hny.filterType = 'Processes';
                param = decodeURIComponent(param.replace(/\+/g, '%20'));
                $scope.hny.filter = {
                    type: param
                };
            };

            var apiBase = '/wp-json/ph-products/v1/products';
            var param = location.search.split('filter=')[1];
            $scope.hny.open = open;
            $scope.hny.isFiltered = isFiltered;
            $scope.hny.filteredCount = filteredCount;
            $scope.hny.resetFilter = resetFilter;
            $scope.hny.doFilter(param, false);
            loadProducts();
        }]);

        angular.module('phmolds', ['controllers', 'directives', 'services', 'filters', 'ngSanitize', 'ngAnimate', 'mm.foundation', 'slick', 'anguvideo', 'angulartics', 'angulartics.google.analytics']);
    })(window.angular);
}